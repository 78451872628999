import request from "@/utils/request";

/**
 *  报价数据api
 * @param data
 * @returns {*}
 */
export const getOfferSearch = data => {
    return request({
        url: '/FileUpLoadApp/SqlDataGet.ashx?pars=' + data,
        method: 'post',
        // data
    })
}
