<template>
  <div class="content">
    <div style="padding: 0 70px">
      <div class="banner">
        <banner></banner>
      </div>
    </div>
    <div class="search_box">
      <!-- 搜索模块 -->
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" label-position="left"
        class="demo-ruleForm">
        <el-row type="flex" justify="start" :gutter="20">
          <el-col :span="10">
            <el-form-item label="类型：" prop="type">
              <el-cascader size="medium" v-model="ruleForm.type" :options="optiontypes" :props="cascaderProps"
                style="width: 250px!important;"></el-cascader>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="发站：" prop="start">
              <el-cascader size="medium" v-model="ruleForm.start" :options="options" :props="cascaderProps"
                style="width: 250px!important;"></el-cascader>
            </el-form-item>
          </el-col>

          <el-col :span="20">
            <div class="box">
              <div class="pointer flex-c op-7" @click="exchange">
                <div class="separator">换</div>
              </div>
              <el-form-item label="到站：" prop="end">
                <el-cascader size="medium" v-model="ruleForm.end" :options="options" :props="cascaderProps"
                  style="width: 250px!important;"></el-cascader>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" size="medium" icon="el-icon-search" @click="submit">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
      <!-- 历史搜索 -->
      <div class="history_search" v-if="offerSearch.length > 0">
        <el-row>
          <el-col :span="20">
            <div class="flexSC wrap">
              <span class="title">历史搜索：</span>
              <div class="history" v-for="(item, index) in offerSearch" :key="index">
                <el-tag class="pointer op-7" type="primary" size="small" @click="getSearchForm(item)"
                  @close="closeTag(item, index)" closable>
                  {{ item.start[0] }} {{ item.start[1] }} → {{ item.end[0] }} {{ item.end[2] }}
                </el-tag>
              </div>
              <div>
                <el-tag class="clean_btn op-7 pointer" type="warning" @click="delSearchForm">清除</el-tag>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 查询统计 -->
      <div class="queries_num">
        <div class="flexSC">
          <img class="logobg" src="../../assets/logo2.png" alt="" />
          <div class="count">
            累计
            <count-to :startVal="0" :endVal="bzcount" :duration="1000"></count-to>
            次查询
          </div>
        </div>
        <div class="right_img">
          <img src="../../assets/magnifying_glass.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 查询结果 -->
    <div class="data_box">
      <div class="title" v-if="dataList.length > 0">
        共为您找到 <span>{{ dataList.length }}</span> 条关于
        <span>{{ ruleForm.start[2] }} → {{ ruleForm.end[2] }}</span> 的报价结果
      </div>
      <el-card class="box-card mb20">
        <div class="data_title flexSC">
          <div class="bar"></div>
          <div class="txt">今日运价</div>
        </div>
        <div class="tip">
          <div>计价规则<i class="el-icon-warning-outline warning"></i></div>
          <div class="txt">注：查询结果仅供参考，具体时效运价已实际为准。</div>
        </div>
        <div class="list">
          <el-table :data="dataList" border stripe size="medium" style="width: 100%">
            <el-table-column prop="id" label="序号"></el-table-column>
            <el-table-column prop="transType" label="产品类型"></el-table-column>
            <el-table-column prop="product" label="品名"></el-table-column>
            <el-table-column prop="bsite" label="发站"></el-table-column>
            <el-table-column prop="esite" label="到站"></el-table-column>
            <el-table-column prop="accmin" label="最低一票"></el-table-column>
            <el-table-column prop="zl1_zd" label="0.00-500.00KG"></el-table-column>
            <!-- <el-table-column prop="zl2_zd" label="500.00-800.00KG"></el-table-column>
            <el-table-column prop="zl3_zd" label="800.00-"></el-table-column>
            <el-table-column prop="zl4_zd" label="3000.00"></el-table-column> -->
            <el-table-column prop="zl5_zd" label="0.00-10.00m3"></el-table-column>
            <!-- <el-table-column prop="zl12_zd" label="10.00-9999.00m3"></el-table-column> -->
          </el-table>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import countTo from "vue-count-to";
import region from '@/global/region.json'
import { getOfferSearch } from '@/api/offer-search'
import { mapGetters, mapActions } from 'vuex'
import Banner from '@/components/Banner'

export default {
  metaInfo: {
    title: '中国物流网,物流,货源,车源,专线',
    meta: [
      {
        name: 'keywords',
        content: '中国物流网,物流,货源,车源,专线'
      },
      {
        name: 'description',
        content: '中国物流网,物流,货源,车源,专线'
      }
    ]
  },
  components: {
    countTo,
    Banner
  },
  data() {
    return {
      page: 1,
      pageSize: 100,
      total: 0,
      bzcount: 0, // 查询次数
      options: region,
      optiontypes: [{
        code: 0,
        name: '全部',
      }, {
        code: 1,
        name: '整车',
      }, {
        code: 2,
        name: '零担',
      }],
      cascaderProps: {
        expandTrigger: 'hover',
        value: 'name',
        label: 'name',
        children: 'children'
      },
      ruleForm: {
        resource: '',
        start: [],
        end: []
      },
      rules: {
        type: [
          { required: true, message: '请输入产品类型', trigger: 'change' }
        ],
        start: [
          { required: true, message: '请输入发起地点', trigger: 'change' }
        ],
        end: [
          { required: true, message: '请输入接收地点', trigger: 'change' }
        ],
      },
      dataList: [],
    };
  },
  computed: {
    ...mapGetters([
      'offerSearch'
    ])
  },
  mounted() {
    this.getSearchCount()
    this.InitSitePar();
  },
  methods: {
    ...mapActions('searchHistory', [
      'setOfferSearch',
      'remoteOfferSearch'
    ]),
    // 删除指定搜索历史
    closeTag(item, index) {
      this.remoteOfferSearch(index)
    },
    // 出发站和到站调换
    exchange() {
      let arr = []
      arr = [...this.ruleForm.start]
      this.ruleForm.start = this.ruleForm.end
      this.ruleForm.end = arr
    },
    // 获取查询次数
    getSearchCount() {
      let params = {
        method: "Query",
        proc: "GET_APP_BZCOUNT",
        pars: [
          {}
        ],
        tmscode: "89569",
        loginsite: "全部",
        token: ""
      }
      this.$api(params, { loading: false }).then(res => {
        this.bzcount = res.msg.length && res.msg[0].out_bzcount
      })
    },
    // 搜索历史填入输入框，并执行搜索
    getSearchForm(item) {
      this.ruleForm = { ...item }
      this.getData()
    },
    // 清除搜索历史
    delSearchForm() {
      this.remoteOfferSearch()
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // 设置历史搜索
          this.setOfferSearch({ ...this.ruleForm })
          this.getData()
        }
      })
    },
    // 获取数据
    getData() {
      let { start, end, type } = this.ruleForm
      let subForm = {}
      subForm.cplx = type.length && type[0]
      subForm.bsheng = start.length && start[0]
      subForm.bcity = start.length && start[1]
      subForm.barea = start.length && start[2]
      subForm.esheng = end.length && end[0]
      subForm.ecity = end.length && end[1]
      subForm.earea = end.length && end[2]
      let params = {
        method: "Query",
        proc: "GET_APP_GXPRICE",
        pars: [
          { ...subForm }
        ],
        tmscode: "89569",
        loginsite: "全部",
        token: ""
      }
      getOfferSearch(JSON.stringify(params)).then(res => {
        res.msg.length && res.msg.forEach((item, index) => {
          item.id = ++index
        })
        this.dataList = res.msg
        this.total = res.msg.length && res.msg[0].count
        this.bzcount++ // 前端自增查询次数，不需要通过接口
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.wrap {
  flex-wrap: wrap;
}

.mb20 {
  margin-bottom: 20px;
}

.flexSC {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.content {
  .el-cascader {
    width: 100% !important;
  }

  .banner {
    width: 100%;
    height: 230px;
    margin-bottom: 20px;
    background: url("../../assets/banner1.png");
    background-size: 100% 100%;
  }

  .search_box {
    padding: 0 70px;
    margin-bottom: 20px;

    .box {
      display: flex;
      align-items: center;

      .separator {
        width: 20px;
        height: 20px;
        color: #f5a623;
        border: 1px solid #f5a623;
        border-radius: 50%;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;

        &:hover {
          animation: heartBeat 1s;
          animation-iteration-count: 1;
        }
      }

      .el-form-item--small.el-form-item {
        margin-bottom: 0 !important;
      }
    }

    .history_search {
      .title {
        //margin-left: 25px;
        font-size: 14px;
        color: #999999;
      }

      .history {
        margin-right: 10px;
        font-size: 14px;
        line-height: 30px;

        .pointer {
          animation: bounceIn 1s;

          &:hover {
            animation: jello 1s;
            animation-iteration-count: 1;
          }
        }
      }

      .clean_btn {
        color: red !important;
      }
    }

    .queries_num {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      .logobg {
        width: 120px;
        height: 40px;
        margin-right: 25px;
        opacity: 0.7;
      }

      .count {
        color: #999999;
      }

      .right_img {
        width: 133px;
        height: 60px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .data_box {
    padding: 0 70px;
    overflow: hidden;

    .title {
      font-size: 14px;
      color: #333333;
      margin-bottom: 40px;

      span {
        color: #eb5e00;
      }
    }

    .data_title {
      margin-bottom: 50px;

      .bar {
        width: 4px;
        height: 18px;
        background: #eb5e00;
        border-radius: 2px;
        margin-right: 30px;
      }

      .txt {
        font-size: 18px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 700;
      }
    }

    .tip {
      font-size: 12px;
      color: #333333;
      //padding: 0 40px;

      .warning {
        color: red;
        margin-left: 10px;
      }

      .txt {
        color: #999999;
        margin: 10px 0;
      }
    }

    .list {
      //padding: 0 40px;
      margin-bottom: 50px;

      .text {
        font-size: 16px;
        color: #FA6400;
        font-weight: 600;
      }
    }
  }
}
</style>
